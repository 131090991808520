
import { ref, defineComponent } from 'vue'
import moment from 'moment-timezone'
moment.locale('ja')
moment.tz.setDefault('Asia/Tokyo')
import * as api from '../../utils/api'
import axios from 'axios'
import { useDialog } from 'naive-ui'
import { workTypes, prefectures, bussnessType } from '../../utils/const'

export default defineComponent({
	data() {
		const formRef = ref(null)
		const refProp = {
			email: '',
			name: '',
			furigana: '',
			addressDetails: '',
			tel: '',
			postcode: '',
			personName: '',
			personNameFurigana: '',
			type: '',
			addApply: 'false',
		}
		const modelRef = ref(refProp)
		const dialog = useDialog()
		const errorDialog = (e: any, onPositiveClick: Function) =>
			dialog.error({
				title: 'Error',
				content: e.message || '不明なエラー',
				closable: false,
				positiveText: 'OK',
				onPositiveClick: () => onPositiveClick(),
			})
		function mailValidate(value: string) {
			if (!value) {
				return new Error('メールアドレスを入力してください。')
			} else if (!/^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9.-]+)$/.test(value)) {
				return new Error('正しい形式でメールアドレスを入力してください。')
			}
			return true
		}
		const bt = bussnessType()
		return {
			formRef,
			model: modelRef,
			errorDialog,
			mailValidate,
			err: '',
			workTypes,
			prefectures,
			bt,
			addressLoading: false,
			totalLoading: false,
            loading: false,
			password: '',
            email: '',
			rules: {
				email: [
					{
						required: true,
						validator(rule: any, value: string) {
							return mailValidate(value)
						},
						trigger: ['input', 'blur'],
					},
				],
				name: [
					{
						required: true,
						validator(rule: any, value: string) {
							if (!value) return new Error('必須の項目です')
						},
						trigger: ['input', 'blur'],
					},
				],
				furigana: [
					{
						required: true,
						validator(rule: any, value: string) {
							if (!value) return new Error('必須の項目です')
							if (!/^[\u3040-\u309F]+$/.test(value)) return new Error('ひらがなで入力してください')
						},
						trigger: ['input', 'blur'],
					},
				],
				personName: [
					{
						required: true,
						validator(rule: any, value: string) {
							if (!value) return new Error('必須の項目です')
						},
						trigger: ['input', 'blur'],
					},
				],
				personNameFurigana: [
					{
						required: true,
						validator(rule: any, value: string) {
							if (!value) return new Error('必須の項目です')
							if (!/^[\u3040-\u309F]+$/.test(value)) return new Error('ひらがなで入力してください')
						},
						trigger: ['input', 'blur'],
					},
				],
				tel: [
					{
						required: true,
						validator(rule: any, value: string) {
							if (!value) return new Error('必須の項目です')
							if (!/^0[0-9]{9,10}/.test(value)) return new Error('ハイフンのない10桁または11桁の半角数字で入力してください')
						},
						trigger: ['input', 'blur'],
					},
				],
				postcode: [
					{
						required: true,
						validator(rule: any, value: string) {
							if (!value) return new Error('必須の項目です')
							if (!/^[0-9]{7}/.test(value)) return new Error('ハイフンのない7桁の半角数字で入力してください')
						},
						trigger: ['input', 'blur'],
					},
				],
				addressDetails: [
					{
						required: true,
						validator(rule: any, value: string) {
							if (!value) return new Error('必須の項目です')
						},
						trigger: ['input', 'blur'],
					},
				],
			},
		}
	},
	mounted: function () {
		document.title = '病院追加 - Vets Index'
		this.init()
	},
	methods: {
		init: async function () {},
		postcodeToAddress: async function () {
			try {
				console.log('fired')
				const value = this.model.postcode
				if (!/^[0-9]{7}/.test(value)) return false
				this.addressLoading = true
				const data = await axios.get(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${value}`)
				const raw = data.data
				if (raw.message) return false
				const r = raw.results[0]
				this.model.addressDetails = `${r.address1}${r.address2}${r.address3}`
				this.addressLoading = false
			} catch (e: any) {
				console.error(e)
			}
		},
		post: async function () {
			const param: any = this.model
			delete param.postcode
			if (param.status) delete param.status
			if (!param.type) return this.errorDialog({ message: '「業種」は必須項目です。' }, () => true)
			try {
                this.loading = true
				const data = await api.post('/v1/admin/add_hospital', param)
                this.email = data.email
                this.password = data.password
				this.loading = false
			} catch (e: any) {
                this.loading = false
				this.errorDialog(e, () => true)
				console.log(this.err)
				console.error(e)
			}
		},
	},
})
